@import '~@truffls/design-system/scss/settings/_colors';

.SelectablePackageListItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: -1px 0 0;
    padding: 12px 24px;
    cursor: pointer;

    border-bottom: 1px solid $color-shades-200;
}
.SelectablePackageListItem:hover {
    background-color: rgba($color-shades-200, 0.12);
}

.SelectablePackageListItem--selected,
.SelectablePackageListItem--selected:hover {
    background-color: rgba($color-shades-200, 0.24);
}

.SelectablePackageListItem__indicator {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 112px;
    height: 24px;
}

.SelectablePackageListItem__indicator-inner {
    display: block;

    min-width: 32px;
    height: 24px;

    padding-left: 4px;
    padding-right: 4px;

    background-color: $color-shades-900;

    color: $color-white;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    font-style: italic;
}

.SelectablePackageListItem__text {
    flex-grow: 1;

    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
}

.SelectablePackageListItem__radio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-right: 34px;
    cursor: pointer;
}

.SelectablePackageListItem__radio-label {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 24px;
}
